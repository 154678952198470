import {
	CourseMangementRouter,
	FeesAssignmentRouter,
	HostelRouter,
	LecturerManagementRouter,
	MainRouter,
	PreviewRouter,
	ReportsRouter,
	ResultManagementRouter,
	StudentManagementRouter,
	UnathorisedRouter,
	UserManagementRouter
} from "../routers";
import { lazy } from "react";
import { InvoiceRouter } from "../routers/invoiceRouter";
import { ApplicationsRouter } from "../routers/applicationsRouter";

const LazyDashboard = lazy(() =>
	import("../../pages/shared/Dashboard/dashboard")
);
const LazyChangePassword = lazy(() =>
	import("../../pages/shared/ChangePassword/changePassword")
);
const LazyPutmeReports = lazy(() =>
	import("../../pages/superAdmin/Reports/pages/PutmeReports/putmeReports")
);
const LazyManageUsers = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageUsers/manageUsers"
	)
);
const LazyManageRoles = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageRoles/manageRoles"
	)
);
const LazyManageMenus = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageMenus/manageMenus"
	)
);
const LazyReferral = lazy(() => import("../../pages/shared/Referral/Referral"));
const LazyViewAllStudents = lazy(() =>
	import("../../pages/superAdmin/StudentManagement/Students/ViewAllStudents")
);
const LazyMenuManagement = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageMenus/menuManagement"
	)
);
const LazyEditProfile = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/Students/ViewProfile/viewProfile"
	)
);

const LazyStudentClaimManagement = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/Students/StudentClaimManagement"
	)
);

const LazyClearStudents = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/ClearStudents/clearStudents"
	)
);

const LazyUnauthorized = lazy(() =>
	import("../../pages/shared/Unauthorized/unauthorized")
);

const LazyAdmissionList = lazy(() =>
	import("../../pages/superAdmin/StudentManagement/ViewAdmissionList")
);

const LazyGraduateStudents = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/GraduateStudents/graduateStudents"
	)
);

const LazyAdmitStudents = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/AdmitStudents/admitStudents"
	)
);

const LazyManageCourse = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/ManageCourse/manageCourse"
	)
);

const LazyAssignCourse = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/AssignCourse/assignCourse"
	)
);

const LazyManageCourseUnit = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/ManageUnitLoad/manageUnitLoad"
	)
);

const LazyAddDropCourse = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/AddDropCourse/addDropCourse"
	)
);

const LazyAssignCreditLoad = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/AssignCreditLoad/assignCreditLoad"
	)
);

const LazyOpenCloseCourseRegistration = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/OpenCloseCourseRegistration/openCloseCourseRegistration"
	)
);
const LazyOpenCloseStudentCourseReg = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/OpenCloseStudentCourseReg/openCloseStudentCourseReg"
	)
);
const LazyMarkStudentForTextbookPurchase = lazy(() =>
	import(
		"../../pages/superAdmin/CourseManagement/pages/StudentTextBookPurchase/libraryClearance"
	)
);

const LazySchoolFeesAssignment = lazy(() =>
	import("../../pages/superAdmin/FeesAssignment/pages/SchoolFees/schoolFees")
);

const LazySchoolFeesAssignmentEdit = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/SchoolFees/schoolFeesEdit"
	)
);
const LazySchoolFeesAssignmentBulk = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/SchoolFees/schoolFeesBulk"
	)
);
const LazyAcceptanceFeeAssignment = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/AcceptanceFee/acceptanceFee"
	)
);
const LazyFeeDiscount = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/FeeDiscount/feeDiscount"
	)
);

const LazySundryFeesAssignment = lazy(() =>
	import("../../pages/superAdmin/FeesAssignment/pages/SundryFees/sundryFees")
);

const LazyPGSchoolFeesAssignment = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/PGSchoolFees/pgSchoolFees"
	)
);
const LazyAssignCourseToLecturer = lazy(() =>
	import(
		"../../pages/superAdmin/LecturerManagement/pages/CourseAssignmentToLecturer/assignCourseToLecturer"
	)
);
const LazyAssignHOD = lazy(() =>
	import(
		"../../pages/superAdmin/LecturerManagement/pages/AssignHOD/assignHOD"
	)
);

const LazyAssignDean = lazy(() =>
	import(
		"../../pages/superAdmin/LecturerManagement/pages/AssignDean/assignDean"
	)
);

const LazyLecturerUpload = lazy(() =>
	import(
		"../../pages/superAdmin/LecturerManagement/pages/LecturerUpload/lecturerUpload"
	)
);
const LazyFilterRecords = lazy(() =>
	import("../../pages/lecturer/Records/pages/FilterRecords/filterRecords")
);
const LazyViewRecords = lazy(() =>
	import("../../pages/lecturer/Records/pages/ViewRecords/viewRecords")
);
const LazyViewResults = lazy(() =>
	import("../../pages/lecturer/Records/pages/ViewResults/viewResults")
);
const LazyViewClassList = lazy(() =>
	import("../../pages/lecturer/Records/pages/ViewClassList/viewClassList")
);
const LazyClassListResultRecords = lazy(() =>
	import(
		"../../pages/superAdmin/ResultManagement/pages/CLassList/SelectRecords/selectRecords"
	)
);
const LazyClasslistViewResultsUploaded = lazy(() =>
	import(
		"../../pages/superAdmin/ResultManagement/pages/CLassList/ViewResults/viewResults"
	)
);
const LazySelectCourseRecords = lazy(() =>
	import(
		"../../pages/superAdmin/ApproveCourses/pages/SelectRecords/selectRecords"
	)
);

const LazyViewCoursesRegistered = lazy(() =>
	import(
		"../../pages/superAdmin/ApproveCourses/pages/ViewCoursesRegistered/viewCoursesRegistered"
	)
);
const LazyScholarshipManagement = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/ScholarshipManagement/scholarshipManagement"
	)
);
const LazyViewScholarshipStudents = lazy(() =>
	import(
		"../../pages/superAdmin/FeesAssignment/pages/ScholarshipManagement/viewScholarshipStudents"
	)
);
const LazyUserClaimManagement = lazy(() =>
	import(
		"../../pages/superAdmin/UserManagement/pages/ManageMenus/userClaimManagement"
	)
);
const LazyNoticeBoard = lazy(() =>
	import("../../pages/superAdmin/NoticeBoard/noticeBoard")
);
const LazyInvoiceManagement = lazy(() =>
	import(
		"../../pages/superAdmin/InvoiceManagement/pages/InvoiceManagement/invoiceManagement"
	)
);
const LazyApplicationSetup = lazy(() =>
	import(
		"../../pages/superAdmin/Application/ApplicationSetup/applicationSetup"
	)
);

const LazyApplicationReset = lazy(() =>
	import(
		"../../pages/superAdmin/Application/ApplicationReset/pages/applicationReset"
	)
);
const LazyDeleteInvoice = lazy(() =>
	import(
		"../../pages/superAdmin/Application/DeleteInvoice/pages/deleteInvoice"
	)
);

const LazyApplicationPaymentReport = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/ApplicationPaymentReport/applicationPaymentReport"
	)
);

const LazyMatricNumberGenerationReports = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/MatricNumberGenerationReports/matricNumberGenerationReports"
	)
);

const LazyChangeOfDepartmentReports = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/ChangeOfDepartmentReport/changeOfDepartmentReports"
	)
);

const LazyPaymentReports = lazy(() =>
	import("../../pages/superAdmin/Reports/pages/PaymentReports/paymentReports")
);

const LazyStudentPaymentReports = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/StudentPaymentReports/studentPaymentReports"
	)
);

const LazyAdmissionListReports = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/AdmissionListReports/admissionListReports"
	)
);
const LazyTransferReports = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/TransferReports/TransferReports"
	)
);
const LazyDEReports = lazy(() =>
	import("../../pages/superAdmin/Reports/pages/DEReports/DEReports")
);

const LazyPutmePreview = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/AdmissionPreviews/putmePreview"
	)
);
const LazyDirectEntryPreview = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/AdmissionPreviews/directEntryPreview"
	)
);
const LazyTransferPreview = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/AdmissionPreviews/transferPreview"
	)
);

const LazyNotifications = lazy(() =>
	import("../../pages/shared/Notifications/notifications")
);

const LazyMangeHostel = lazy(() =>
	import(
		"../../pages/superAdmin/HostelManagement/ManageHostels/manageHostels"
	)
);

const LazyViewRooms = lazy(() =>
	import(
		"../../pages/superAdmin/HostelManagement/ManageHostels/components/viewRoom"
	)
);
const LazyViewBedspaces = lazy(() =>
	import(
		"../../pages/superAdmin/HostelManagement/ManageHostels/components/viewBedspaces"
	)
);

const LazyCategoryManagement = lazy(() =>
	import(
		"../../pages/superAdmin/HostelManagement/ManageCategory/manageCategory"
	)
);

const LazyManageHostelReservation = lazy(() =>
	import(
		"../../pages/superAdmin/HostelManagement/ManageReservations/manageReservations"
	)
);

const LazySessionManager = lazy(() =>
	import(
		"../../pages/superAdmin/StudentManagement/SessionManager/sessionManager"
	)
);

const LazyBookShopReport = lazy(() =>
	import(
		"../../pages/superAdmin/Reports/pages/BookShopReports/bookShopReports"
	)
);

const LazyScholarshipReport = lazy(() => 
	import(
		"../../pages/superAdmin/Reports/pages/ScholarshipReports/scholarshipReports"
	)
);

export const staffRoutes = [
	//add super admin routes here
	{
		path: "/dashboard",
		component: LazyDashboard,
		exact: true,
		router: MainRouter,
		title: "Dashboard"
	},
	{
		path: "/notice_board_management",
		component: LazyNoticeBoard,
		exact: true,
		router: MainRouter,
		title: "Notice board setup"
	},
	{
		path: "/referral",
		component: LazyReferral,
		exact: true,
		router: MainRouter,
		title: "Referral"
	},
	{
		path: "/reports/putme_reports",
		component: LazyPutmeReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/user_management/roles",
		component: LazyManageRoles,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/user_management/users",
		component: LazyManageUsers,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/user_management/users/edit",
		component: LazyEditProfile,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/user_management/menus",
		component: LazyManageMenus,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/user_management/menus/management",
		component: LazyMenuManagement,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/student_management/view",
		component: LazyViewAllStudents,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/search_student",
		component: LazyViewAllStudents,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/view/edit",
		component: LazyEditProfile,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/manage_claims",
		component: LazyStudentClaimManagement,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/session_manager",
		component: LazySessionManager,
		exact: true,
		router: StudentManagementRouter,
		title: "Session Manager"
	},
	{
		path: "/user_management/users/management",
		component: LazyUserClaimManagement,
		exact: true,
		router: UserManagementRouter,
		title: "User Management"
	},
	{
		path: "/student_management/clear",
		component: LazyClearStudents,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/change_password",
		component: LazyChangePassword,
		exact: true,
		router: MainRouter,
		title: "Change Password"
	},
	{
		path: "/no_access",
		component: LazyUnauthorized,
		exact: true,
		router: UnathorisedRouter,
		title: "Unauthorized access"
	},
	{
		path: "/student_management/admission_list",
		component: LazyAdmissionList,
		exact: true,
		router: StudentManagementRouter,
		title: "Student Management"
	},
	{
		path: "/student_management/search_admission_list",
		component: LazyAdmissionList,
		exact: true,
		title: "Student Management",
		router: StudentManagementRouter
	},
	{
		path: "/student_management/graduation",
		component: LazyGraduateStudents,
		exact: true,
		title: "Student Management",
		router: StudentManagementRouter
	},
	{
		path: "/student_management/admit",
		component: LazyAdmitStudents,
		exact: true,
		title: "Student Management",
		router: StudentManagementRouter
	},
	{
		path: "/course_management/manage_course",
		component: LazyManageCourse,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/assign_course",
		component: LazyAssignCourse,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/manage_load",
		component: LazyManageCourseUnit,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/add_drop",
		component: LazyAddDropCourse,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/assign_unit",
		component: LazyAssignCreditLoad,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/open_reg",
		component: LazyOpenCloseCourseRegistration,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/open_student_reg",
		component: LazyOpenCloseStudentCourseReg,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/course_management/library_clearance",
		component: LazyMarkStudentForTextbookPurchase,
		exact: true,
		router: CourseMangementRouter,
		title: "Course Management"
	},
	{
		path: "/lecturer_management/assign_courses",
		component: LazyAssignCourseToLecturer,
		exact: true,
		router: LecturerManagementRouter,
		title: "Assign Course To Lecturer"
	},
	{
		path: "/lecturer_management/assign_staff/hod",
		component: LazyAssignHOD,
		exact: true,
		router: LecturerManagementRouter,
		title: "Assign HOD"
	},
	{
		path: "/lecturer_management/assign_staff/dean",
		component: LazyAssignDean,
		exact: true,
		router: LecturerManagementRouter,
		title: "Assign Dean"
	},
	{
		path: "/lecturer_management/lecturer_upload",
		component: LazyLecturerUpload,
		exact: true,
		router: LecturerManagementRouter,
		title: "Lecturer Upload"
	},
	{
		path: "/fees_assignment/school_fees",
		component: LazySchoolFeesAssignment,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/school_fees/edit",
		component: LazySchoolFeesAssignmentEdit,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/school_fees/bulk",
		component: LazySchoolFeesAssignmentBulk,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/sundry_fees",
		component: LazySundryFeesAssignment,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/acceptance_fees",
		component: LazyAcceptanceFeeAssignment,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},

	{
		path: "/fees_assignment/fees_discount",
		component: LazyFeeDiscount,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},

	{
		path: "/fees_assignment/pg_school_fees",
		component: LazyPGSchoolFeesAssignment,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Fees assignment"
	},
	{
		path: "/fees_assignment/scholarship_management",
		component: LazyScholarshipManagement,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Scholarship Management"
	},
	{
		path: "/fees_assignment/scholarship_management/view_scholarship",
		component: LazyViewScholarshipStudents,
		exact: true,
		router: FeesAssignmentRouter,
		title: "Scholarship Management"
	},
	{
		path: "/records",
		component: LazyFilterRecords,
		exact: true,
		router: MainRouter,
		title: "Results & Class List"
	},
	{
		path: "/records/view",
		component: LazyViewRecords,
		exact: true,
		router: MainRouter,
		title: "Results & Class List"
	},
	{
		path: "/records/results",
		component: LazyViewResults,
		exact: true,
		router: MainRouter,
		title: "Results & Class List"
	},
	{
		path: "/records/classList",
		component: LazyViewClassList,
		exact: true,
		router: MainRouter,
		title: "Results & Class List"
	},
	{
		path: "/results/classlist",
		component: LazyClassListResultRecords,
		exact: true,
		router: ResultManagementRouter,
		title: "Result Management"
	},
	{
		path: "/results/classlist/view",
		component: LazyClasslistViewResultsUploaded,
		exact: true,
		router: MainRouter,
		title: "Result Management"
	},
	{
		path: "/approve_courses",
		component: LazySelectCourseRecords,
		exact: true,
		router: MainRouter,
		title: "Approve & Unapprove Courses"
	},
	{
		path: "/approve_courses/view",
		component: LazyViewCoursesRegistered,
		exact: true,
		router: MainRouter,
		title: "Approve & Unapprove Courses"
	},
	{
		path: "/reports/payment_reports",
		component: LazyPaymentReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/reports/student_fee_reports",
		component: LazyStudentPaymentReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/reports/bookshop_reports",
		component: LazyBookShopReport,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/reports/application_payment_reports",
		component: LazyApplicationPaymentReport,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/reports/matric_number_generation_reports",
		component: LazyMatricNumberGenerationReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/reports/change_of_department_reports",
		component: LazyChangeOfDepartmentReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/reports/admission_list",
		component: LazyAdmissionListReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/app_reports/transfer_reports",
		component: LazyTransferReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/app_reports/de_reports",
		component: LazyDEReports,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	},
	{
		path: "/invoice_management/invoice",
		component: LazyInvoiceManagement,
		exact: true,
		router: InvoiceRouter,
		title: "Invoice Management"
	},
	{
		path: "/applications/setup",
		component: LazyApplicationSetup,
		exact: true,
		router: ApplicationsRouter,
		title: "Applications"
	},
	{
		path: "/applications/reset",
		component: LazyApplicationReset,
		exact: true,
		router: ApplicationsRouter,
		title: "Applications"
	},
	{
		path: "/applications/delete",
		component: LazyDeleteInvoice,
		exact: true,
		router: ApplicationsRouter,
		title: "Applications"
	},
	{
		path: "/putme_application/preview",
		router: PreviewRouter,
		component: LazyPutmePreview,
		exact: true
	},
	{
		path: "/direct_entry_application/preview",
		router: PreviewRouter,
		component: LazyDirectEntryPreview,
		exact: true
	},
	{
		path: "/transfer_application/preview",
		router: PreviewRouter,
		component: LazyTransferPreview,
		exact: true
	},
	{
		path: "/notifications",
		component: LazyNotifications,
		exact: true,
		router: MainRouter,
		title: "Notifications"
	},
	{
		path: "/hostel_management/manage_hostel",
		component: LazyMangeHostel,
		exact: true,
		router: HostelRouter,
		title: "Hostel Management"
	},
	{
		path: "/hostel_management/manage_hostel/view_hostel",
		component: LazyViewRooms,
		exact: true,
		router: HostelRouter,
		title: "Hostel Management"
	},
	{
		path: "/hostel_management/manage_hostel/view_hostel/view_bedspaces",
		component: LazyViewBedspaces,
		exact: true,
		router: HostelRouter,
		title: "Hostel Management"
	},
	{
		path: "/hostel_management/manage_category",
		component: LazyCategoryManagement,
		exact: true,
		router: HostelRouter,
		title: "Hostel Management"
	},
	{
		path: "/hostel_management/manage_reservation",
		component: LazyManageHostelReservation,
		exact: true,
		router: HostelRouter,
		title: "Hostel Management"
	},
	{
		path: "/reports/scholarship_reports",
		component: LazyScholarshipReport,
		exact: true,
		router: ReportsRouter,
		title: "Reports"
	}
];
